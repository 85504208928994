














import { Component, Vue } from 'vue-property-decorator';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import FEATURES from '@/modules/common/constants/features.constant';
import MarketsHintTooltip from '@/modules/markets/components/markets-hint-tooltip.vue';

@Component({
    components: {
        DateDocumentFilter,
        MarketsHintTooltip,
    },
})
export default class MarketsClusterHeader extends Vue {
    get feature() {
        return FEATURES.MARKET;
    }
}
