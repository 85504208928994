

















































































import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import { Prop, Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import MarketsCalendarLine from './markets-calendar-line.vue';
import MarketsClusterTooltip from './markets-cluster-tooltip.vue';

@Component({
    components: {
        MarketsCalendarLine,
        MarketsClusterTooltip,
    },
})
export default class MarketsHotelItem extends Vue {
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(MarketsServiceS) marketsService!: MarketsService;
    @Inject(UserServiceS) userService!: UserService;
    @Inject(ClusterMarketsServiceS) clusterMarketsService!: ClusterMarketsService;

    @Prop({ type: Object, default: () => ({}) }) hotelData!: ClusterHotelsMarketsModel;

    activeDay: number | null = null;
    activeColor: string | null = null;
    isVisible = false;

    get mainCompset() {
        if (this.skeleton) return null;
        return this.clusterService.getMainCompset(this.hotelData);
    }

    get skeleton() {
        return !this.hotelData.hotelName;
    }

    get formatedScoreDifference() {
        if (this.skeleton) return null;
        const score = Math.round(this.hotelData.newTotalScore);
        return `${score > 0 ? '+' : ''}${score}`;
    }

    get urlChainClusterParam() {
        if (this.userService.isClusterUser) {
            return 'cluster';
        }

        return 'chain';
    }

    get averagePosition() {
        if (!this.mainCompset) return '-';

        const positions = this.documentFiltersService.days
            .map(day => (this.hotelData.compsetMain && this.clusterMarketsService
                .getPosition(day, this.hotelData.hotelId, this.hotelData.compsetMain)) || 0)
            .filter(position => position !== 0);

        const averagePosition = positions
            .reduce((prev, curr) => prev + curr, 0) / positions.length;

        return Math.round(averagePosition) || '-';
    }

    async goToHotel() {
        this.documentFiltersService.compsetIdFromHighLevel = null;
        this.documentFiltersService.compsetId = null;
        this.marketsService.storeState.providersMarketsDocuments = {};
        this.documentFiltersService.savePos(null);

        await this.$router.push({
            name: `${this.$route.name!}.hotel`,
            params: {
                hotelId: String(this.hotelData.hotelId),
            },
        });
    }

    mounted() {
        this.checkVisibilityState();
    }

    beforeDestroy() {
        window.dispatchEvent(new CustomEvent('componentDestroy', { detail: this }));
    }

    setActiveDay(el: HTMLDivElement) {
        const day = Number(el.getAttribute('data-day'));

        if (day) {
            this.activeDay = day;
            this.activeColor = el.getAttribute('data-color');
        }
    }

    hideTooltip() {
        this.activeDay = null;
    }

    openPopup() {
        if (!this.mainCompset) return;
        this.$router.push({
            name: `${this.$route.name!}.day-markets`,
            params: {
                day: String(this.activeDay),
                hotelId: String(this.hotelData.hotelId),
                compsetId: this.mainCompset.id,
            },
        });
    }

    checkVisibilityState() {
        if (!this.$parent) {
            return;
        }

        const { wrapper: scrollView } = this.$parent.$refs as { wrapper: HTMLDivElement };
        const { container } = this.$refs as { container: HTMLDivElement };

        if (!container) return;

        const updateVisibilityState = () => {
            const a = container.getBoundingClientRect();
            const b = scrollView.getBoundingClientRect();

            this.isVisible = a.top + a.height > b.top && a.top < b.top + b.height;
        };
        scrollView.addEventListener('scroll', updateVisibilityState);
        window.addEventListener('componentDestroy', (e: CustomEventInit<Vue>) => {
            if (e.detail === this) {
                scrollView.removeEventListener('scroll', updateVisibilityState);
            }
        });

        updateVisibilityState();
    }
}
