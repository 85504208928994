

































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';
import getScrollbarWidth from '@/modules/common/filters/scrollbar-width.filter';
import Day from '@/modules/common/types/day.type';
import DocumentFiltersService, {
    DocumentFiltersServiceS,
} from '@/modules/document-filters/document-filters.service';
import ClusterService, {
    ClusterServiceS,
} from '@/modules/cluster/cluster.service';
import ClusterMarketsService, {
    ClusterMarketsServiceS,
} from '@/modules/cluster/cluster-markets.service';
import DotsSpinner from '@/modules/common/components/dots-spinner.vue';
import WeekdayLetter from '@/modules/cluster/filters/weekend-letter.filter';
import MarketsClusterHotelItem from './markets-cluster-hotel-item.vue';

@Component({
    components: {
        MarketsClusterHotelItem,
        DotsSpinner,
    },
    filters: {
        DateFilter: (value: Date | null) => {
            if (!value) return '';
            return moment(value).format('D/M');
        },
        WeekdayLetter,
    },
})
export default class MarketsClusterList extends Vue {
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(DocumentFiltersServiceS)
    documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterMarketsServiceS) clusterMarketsService!: ClusterMarketsService;

    isFirstLoading = true;
    contentHeight: string | null = null;

    get monthDays() {
        return this.documentFiltersService.days;
    }

    get dateScaleStyles() {
        const scrollWidth = getScrollbarWidth();
        return {
            paddingRight: `${scrollWidth + 15}px`,
        };
    }

    get hotels() {
        if (this.clusterMarketsService.clusterMarketsHotels) {
            // NOTE: Disabled because of strange sorting behavior
            // this.clusterService.clusterHotels
            //     .sort((a, b) => a.hotelName
            //         .localeCompare(b.hotelName));
        }
        return this.clusterMarketsService.clusterMarketsHotels;
    }

    sort() {
        this.clusterMarketsService.toggleScoreSort();
    }

    isMonday(day: Day) {
        return this.date(day).getDay() === 1;
    }

    calcHeight() {
        const el: HTMLElement | null = document.querySelector('.wrapper');
        const footer: HTMLElement | null = document.querySelector('footer');
        if (el && footer) {
            const table = el.getBoundingClientRect();
            const footerBox = footer.getBoundingClientRect();
            const height = window.innerHeight - (table.top + footerBox.height + 50);
            this.contentHeight = `${height}px`;
            const { limit } = this.clusterService.storeState;
            const listItemMinHeight = 108;
            this.clusterService.storeState.limit = Math.ceil(height / listItemMinHeight / limit) * limit;
        }
    }

    isSunday(day: Day) {
        return this.date(day).getDay() === 0;
    }

    isPast(day: Day) {
        return this.documentFiltersService.isPreviousDay(day);
    }

    isToday(day: Day) {
        return this.documentFiltersService.isCurrentDay(day);
    }

    mounted() {
        document.addEventListener('scroll', this.handleScroll);
        this.$nextTick(() => {
            this.calcHeight();
        });
    }

    beforeDestroy() {
        document.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.calcHeight);
    }

    created() {
        window.addEventListener('resize', this.calcHeight);
    }

    handleScroll(e: Event) {
        const targetElement = e.target as HTMLElement;
        const minDistanceToBottom = 10;
        const scrollMax = Math.max(
            targetElement.scrollHeight,
            targetElement.offsetHeight,
            targetElement.clientHeight,
        );

        const scrollCurrent = Math.round(
            window.innerHeight
        + Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            targetElement.scrollTop,
        ),
        );
        const isBottomOfWindow = scrollMax - scrollCurrent < 0
      || scrollMax - scrollCurrent <= minDistanceToBottom;

        if (isBottomOfWindow) {
            this.isFirstLoading = false;
            this.clusterService.loadMoreData('markets');
        }
    }

    date(day: Day) {
        const { month, year } = this.documentFiltersService;
        return new Date(year, month, day);
    }
}
