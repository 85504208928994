var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cluster-list"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"cluster-list__header-info"},[_c('div',{class:{
          title: true,
          skeleton: _vm.clusterService.isLoading,
        },domProps:{"innerHTML":_vm._s('Hotel name')}}),_c('div',{staticClass:"score",class:{
          score: true,
          skeleton: _vm.clusterService.isLoading,
        }},[_vm._v(" Average position ")])]),_c('div',{class:{
        'dates-scale': true,
        skeleton: _vm.clusterService.isLoading,
      },style:(_vm.dateScaleStyles)},_vm._l((_vm.monthDays),function(day,i){return _c('div',{key:i,class:{
          'dates-scale__cell': true,
          'dates-scale__cell--bold': _vm.isMonday(day) || _vm.isSunday(day),
          'dates-scale__cell--current': _vm.isToday(day),
        }},[_vm._v(" "+_vm._s(_vm._f("WeekdayLetter")(_vm.date(day)))+_vm._s(day)+" ")])}),0)]),_c('div',{ref:"wrapper",staticClass:"wrapper",style:({ height: _vm.contentHeight }),on:{"scroll":_vm.handleScroll}},[(_vm.clusterMarketsService.isLoading)?_vm._l((7),function(n){return _c('MarketsClusterHotelItem',{key:n,staticClass:"markets-cluster-item-layout",style:({
          opacity: 1 - n / 7,
        })})}):_vm._l((_vm.hotels),function(item,index){return _c('MarketsClusterHotelItem',{key:index,attrs:{"hotelData":item}})}),_c('DotsSpinner',{attrs:{"value":_vm.clusterMarketsService.storeState.isLoadingMore}})],2),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }