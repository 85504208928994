var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-chain",on:{"mouseover":_vm.checkCell,"mouseleave":_vm.cellOut,"click":_vm.cellClick}},[(!_vm.skeleton)?_vm._l((_vm.calculatedAssestments),function(type,index){
var _obj;
return _c('div',{key:index,class:( _obj = {
                'calendar-chain__cell': true,
                'calendar-chain__cell--past': type.isPastDay
            }, _obj['calendar-chain__cell--' + type.colorModificator] = true, _obj['calendar-chain__cell--na'] =  type.isNA, _obj ),attrs:{"data-day":index + 1,"data-color":type.colorModificator}},[(!_vm.disableEvents)?_c('div',{staticClass:"calendar-chain__events-indicator"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasLocalEvents(index + 1)),expression:"hasLocalEvents(index + 1)"}],staticClass:"calendar-chain__event-mark"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasHolidayEvents(index + 1)),expression:"hasHolidayEvents(index + 1)"}],class:[
                        'calendar-chain__event-mark',
                        'calendar-chain__event-mark--local'
                    ]})]):_vm._e()])}):_vm._l((30),function(n){return _c('div',{key:n,class:[
                'calendar-chain__cell',
                'calendar-chain__cell--skeleton'
            ]},[_c('div',{staticClass:"skeleton skeleton--simple"})])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }