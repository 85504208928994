var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageWrapper',{staticClass:"markets-cluster-list",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('MarketsHeader')]},proxy:true},{key:"main",fn:function(){return [_c('div',[_c('MarketsActions'),_c('MarketsList')],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"markets-cluster-footer"},[_c('div',[_vm._v(" Position comparing to competitors: ")]),_c('div',{staticClass:"calendar-legend"},[_c('div',{class:[
                    'calendar-legend__cell',
                    'calendar-legend__cell--dark-green' ]},[_vm._v(" High ")]),_c('div',{class:[
                    'calendar-legend__cell',
                    'calendar-legend__cell--green' ]},[_vm._v(" Med-High ")]),_c('div',{class:[
                    'calendar-legend__cell',
                    'calendar-legend__cell--yellow' ]},[_vm._v(" Med-Low ")]),_c('div',{class:[
                    'calendar-legend__cell',
                    'calendar-legend__cell--red' ]},[_vm._v(" Poor ")]),_c('div',{class:[
                    'calendar-legend__cell' ]},[_vm._v(" No Data ")])])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }