
























































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import ChainService, { ChainServiceS } from '@/modules/chain/chain.service';
import MarketsHeader from '../components/markets/markets-cluster-header.vue';
import MarketsActions from '../components/markets/markets-cluster-actions.vue';
import MarketsList from '../components/markets/list/markets-cluster-list.vue';
import ClusterService, { ClusterServiceS } from '../cluster.service';

@Component({
    components: {
        PageWrapper,
        MarketsHeader,
        MarketsActions,
        MarketsList,
    },
})
export default class MarketClusterPage extends Vue {
    @Inject(ChainServiceS)
    private chainService!: ChainService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    @Inject(ClusterMarketsServiceS)
    private clusterMarketsService!: ClusterMarketsService;

    beforeMount() {
        this.chainService.resetChainPair();

        // NOTE: Here will chain logic (CI-3824)

        this.clusterService.hotels = null;
        this.clusterMarketsService.resetLoading();
        this.clusterService.saveCurrentProvider(this.clusterMarketsService.providers[0]);
    }
}
