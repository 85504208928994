











































import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        CustomTooltip,
    },
})
export default class MarketsHintTooltip extends Vue {}
