

































import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import Day from '@/modules/common/types/day.type';
import EventsModel from '@/modules/events/models/events.model';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';
import Currency from '@/modules/common/components/currency.vue';
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

@Component({
    filters: {
        PercentFilter,
        PriceFilter,
        DateFilter: (value : (Date |null)) => {
            if (!value) return '';
            return moment(value).format('ddd, MMM DD, YYYY');
        },
    },
    components: { Currency, Flag },
})

export default class MarketsClusterTooltip extends Vue {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(EventsManagerServiceS) eventsManagerService!: EventsManagerService;
    @Inject(ClusterMarketsServiceS) clusterMarketsService!: ClusterMarketsService;
    @Inject(MarketsCommonServiceS) marketsCommonService!: MarketsCommonService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;

    @Prop({
        type: Number,
        required: true,
    })
    day!: Day;

    @Prop({
        type: String,
    })
    color!: string;

    @Prop({
        type: Object,
        required: false,
    })
    public hotelData!: ClusterHotelsMarketsModel;
    public containerHeight: number = 0;
    public isMounted = false;

    get date() {
        const { year, month } = this.documentFiltersService;
        return new Date(year, month, this.day);
    }

    get isLeft() {
        return this.day > this.documentFiltersService.days.length / 2;
    }

    get offset() {
        const daysCount = this.documentFiltersService.days.length;
        const segmentStep = 100 / daysCount;

        if (!this.isMounted) return { transition: '0s' };
        const leftOffset = segmentStep * (this.day + (this.isLeft ? -1 : 0));

        return {
            left: `${leftOffset}%`,
            transform: this.isLeft ? 'translate3d(-100%, -50%, 0)' : 'translate3d(0, -50%, 0)',
        };
    }

    get flagList() {
        const events = (this.getEventsCalendar()[this.day - 1] || []);
        return Array.from(
            new Set(
                events.map(e => e.countryCode),
            ),
        );
    }

    get mainCompset() {
        return this.clusterService.getMainCompset(this.hotelData);
    }

    get position() {
        if (!this.mainCompset) return null;

        const { day, hotelData, mainCompset } = this;
        const { hotelId } = hotelData;
        const { id: compsetId } = mainCompset;

        const document = this.clusterService
            .getMainCompsetData(hotelId) as MarketsCompsetMainModel;

        if (!document) return null;

        return this.marketsCommonService
            .getPosition(day, hotelId, document);
    }

    get page() {
        if (!this.mainCompset) return null;

        const chainDocument = this.clusterService.getMainCompsetData(this.hotelData.hotelId) as MarketsCompsetMainModel;

        if (!chainDocument) return null;

        return this.clusterMarketsService.getPage(
            this.day,
            this.hotelData.hotelId,
            chainDocument,
        );
    }

    get flagsMoreBy() {
        return this.flagList.length - 2;
    }

    get hasLocalEvents() {
        return this.eventsManagerService.hasLocalEventsByDay({ day: this.day });
    }

    getEventsCalendar() {
        const { year, month } = this.documentFiltersService;
        const events = this.eventsManagerService.getHolidayEventsByMonth({ month, year }).holiday;
        const eventsCalendar: { [k: number]: EventsModel[] | null } = {};

        events.forEach(event => {
            const start = new Date(event.startDate!);
            const startIndex = start.getDate() - 1;

            eventsCalendar[startIndex] = eventsCalendar[startIndex] || [];
            eventsCalendar[startIndex]!.push(event);
        });

        return eventsCalendar;
    }

    mounted() {
        this.isMounted = true;
    }

    beforeUnmount() {
        this.isMounted = false;
    }
}
