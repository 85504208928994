




import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';

@Component({
    components: { CustomSelect },
})
export default class MarketsChainProviderTypeFilter extends Vue {
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(ClusterMarketsServiceS) clusterMarketsService!: ClusterMarketsService;

    get provider() {
        return this.clusterService.currentProvider;
    }
    set provider(value) {
        this.clusterService.saveCurrentProvider(value);
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }

    get providerItems(): Item[] {
        return this.clusterMarketsService.providers
            .filter(provider => !!provider)
            .map(provider => ({
                value: provider,
                name: this.getProviderLabel(provider),
            }));
    }
}
